<template>
  <div>
    <b-row class="no-gutters justify-content-between">
      <b-col class="col-8">
        <b-form-group
          :label="$t('page.issue.typeLabel')"
          label-class="font-weight-bold"
        >
          <b-form-checkbox-group v-model="types" id="maintainer-type">
            <b-row class="no-gutters justify-content-between">
              <b-col
                class="col-4"
                v-for="(type, index) in typeOptions"
                :key="index"
              >
                <b-form-checkbox :value="type">
                  {{ $t(`fixCategories.${type}`) }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col class="col-4">
        <search-input
          v-model="searchInput"
          label="search"
          :placeholder="$t('page.maintainer.maintainerName')"
          @search="search"
        ></search-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchInput from "../Base/BaseSearchInput";
export default {
  components: {
    SearchInput
  },
  props: {
    storeName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      searchInput: ""
    };
  },
  mounted() {
    this.searchInput = this.searchText;
  },
  computed: {
    types: {
      get() {
        return this.$store.getters[`${this.storeName}/getFilterType`];
      },
      set(value) {
        this.$store.commit(`${this.storeName}/SET_FILTER_TYPE`, value);
        this.retrieveMaintainers();
      }
    },
    searchText: {
      get() {
        return this.$store.getters[`${this.storeName}/getFilterSearch`];
      },
      set(value) {
        this.$store.commit(`${this.storeName}/SET_FILTER_SEARCH`, value);
      }
    },
    typeOptions() {
      return this.$store.getters[`${this.storeName}/getTypeOptions`];
    }
  },
  methods: {
    search() {
      this.searchText = this.searchInput;
      this.retrieveMaintainers();
    },
    async retrieveMaintainers() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch(`${this.storeName}/retrieveMaintainers`);
      } catch {
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
