<template>
  <b-modal
    v-model="active"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-cyan-400">
        {{ maintainer.name }}
      </h5>
    </template>
    <template v-slot:default>
      <b-row class="row no-gutters justify-content-between mb-5">
        <b-col class="col-12 col-sm-auto">
          <h5>{{ $t("address") }}</h5>
          <div>{{ maintainer.address }} {{ maintainer.streetNumber }}</div>
          <div>{{ maintainer.zip }}, {{ maintainer.city }}</div>
          <div>{{ maintainer.country }} ({{ maintainer.province }})</div>
        </b-col>
        <b-col class="col-12 col-sm-auto">
          <h5>{{ $t("contact") }}</h5>
          <h6>{{ maintainerFullName }}</h6>
          <div>{{ maintainer.mobile }}</div>
          <div>{{ maintainer.landline }}</div>
          <div>{{ maintainer.email }}</div>
          <div v-if="$is1Board">{{ maintainer.userId.email }}</div>
        </b-col>
      </b-row>
      <h5>{{ $t("page.maintainer.offeredServices") }}</h5>

      <b-badge
        v-for="(type, index) in maintainer.type"
        :key="index"
        class="text-white bg-cyan-400 mr-1"
      >
        <span class="font-weight-normal">
          {{ $is1Board ? type.description : $t(`fixCategories.${type}`) }}
        </span>
      </b-badge>

      <div v-if="$is1Board" class="mt-5">
        <p class="font-weight-bold">
          {{ $t("page.maintainer.associatedWithCondominiums") }}
        </p>

        <condominium-table
          :condominiums="maintainerCondominiums"
          :fields="condominiumFields"
        />
      </div>

      <hr class="my-4 mx-n3" />
      <b-row class="row no-gutters justify-content-end">
        <b-col class="col-12 col-sm-auto" order="2" order-sm="1">
          <b-button variant="outline-secondary" block @click="closeModal">
            {{ $t("button.close") }}
          </b-button>
        </b-col>
        <b-col
          v-if="isAssociateBtnVisible && !isAssociated"
          class="col-12 col-sm-auto ml-0 ml-sm-1 mb-1 mb-sm-0"
          order="1"
          order-sm="2"
        >
          <b-button
            variant="primary"
            class="text-white"
            block
            @click="associateMaintainer"
          >
            {{ $t("button.associate") }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import authMixin from "./../../mixins/authMixin";
import userMixin from "./../../mixins/userMixin";
import appMixin from "./../../mixins/appMixin";

import uniqBy from "lodash/uniqBy";

import CondominiumTable from "./../Condominium/CondominiumTable";

export default {
  props: {
    modalState: {
      type: Boolean,
      default: false
    },
    maintainer: {
      type: [Object, null],
      default: null
    },
    isAssociateBtnVisible: {
      type: Boolean,
      default: true
    }
  },
  components: { CondominiumTable },
  mixins: [authMixin, userMixin, appMixin],
  data() {
    return {
      condominiumFields: [
        { key: "name", label: "name" },
        { key: "address", label: "address" }
      ]
    };
  },
  computed: {
    active: {
      get() {
        return this.modalState;
      },
      set(value) {
        this.$emit("update:modalState", value);
      }
    },
    maintainerCondominiums() {
      const condominiums = this.$store.getters[
        "userMaintainer/getCurrentMaintainerCondominiums"
      ];
      return uniqBy(condominiums, "condominium._id").map(data => ({
        id: data.condominium._id,
        name: data.condominium.name,
        address: this.formatCondominiumAddress(data.condominium)
      }));
    },
    maintainerFullName() {
      const fullName = this.$_getUserFullName(this.maintainer.userId?.profile);
      return fullName;
    },
    isAssociated() {
      return (
        this.maintainer.administrators.findIndex(
          admin => admin === this.$_authUser._id
        ) > -1
      );
    }
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    async associateMaintainer() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch(
          "maintainer/addAdminMaintainer",
          this.maintainer._id
        );
        await this.$store.dispatch("maintainer/retrieveAdminMaintainers");
        await this.$store.dispatch("maintainer/retrieveMaintainers");

        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    formatCondominiumAddress(condominium) {
      return [
        condominium.address,
        condominium.streetNumber,
        condominium.city,
        condominium.province
      ].join(", ");
    }
  }
};
</script>

<style lang="scss" scoped></style>
